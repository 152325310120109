import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'

const combinedReducer = {
  header: headerSlice,
  modal: modalSlice,
}

export default configureStore({
  reducer: combinedReducer
})