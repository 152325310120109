import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
// import { jwtDecode } from "jwt-decode";

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))

initializeApp()

let token = checkAuth()

function App() {
  useEffect(() => {
    themeChange(false)

    // if (token) {
    //   const now = new Date().getTime();
    //   const decoded = jwtDecode(token);
    //   console.log(decoded.exp, now, "time")
    //   if (decoded.exp < now) {
    //     token = null
    //   } else {
    //     token = checkAuth()
    //   }
    // }
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/app/*" element={<Layout />} />
          <Route path="*" element={<Navigate to={token ? "/app/home" : "/login"} replace />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
