import axios from "axios"

const initializeApp = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://sms.sejahteragroup.net/api'

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

    } else {
        console.log = () => { };
    }
}

export default initializeApp